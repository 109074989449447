<template>
    <ion-page>
        <ion-header>
            <ion-toolbar
                style="--background: #03a9f4; color: white; --min-height: 36px"
                class="custom-toolbar"
            >
                <div class="row align-center">
                    <slot name="before">
                        <div class="shrink">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                                @click="$router.go(-1)"
                            />
                        </div>
                    </slot>
                    <slot name="center">
                        <div class="grow text-xs-center" :style="'opacity: 1'">
                            KÝ LỆNH CỐ ĐỊNH THÔNG TIN
                        </div>
                    </slot>
                    <slot name="after">
                        <div class="shrink" style="opacity: 0">
                            <DxButton
                                type="default"
                                icon="mdi mdi-magnify "
                                class="ml-1"
                            />
                        </div>
                    </slot>
                </div>
            </ion-toolbar>
        </ion-header>

        <ion-content :scroll-events="false" class="content-box">
            <div
                class="row mt-2 mb-2 px-2"
                style="height: calc(100vh - 48px - 36px - 16px); overflow-y: auto"
            >
                <div class="xs12">
                    <div
                        class="row align-center justify-center text-xs-center"
                        style="flex-wrap: wrap"
                    >
                        <i class="mdi mdi-bus-stop color-primary"></i>
                        <span class="font-medium color-primary">
                            {{ ThongTinKyLenh.QueryRouter.TenBenDi }}
                        </span>
                        <span class="font-medium">
                            {{ `\xa0-\xa0` }}
                        </span>
                        <i class="mdi mdi-map-marker-down color-success"></i>
                        <span class="font-medium color-success">
                            {{ ThongTinKyLenh.QueryRouter.TenBenDen }}
                        </span>
                        <span class="font-medium">
                            {{
                                ThongTinKyLenh.QueryRouter.MaTuyen
                                    ? `\xa0(${ThongTinKyLenh.QueryRouter.MaTuyen})`
                                    : ``
                            }}
                        </span>
                    </div>

                    <div class="row font-medium color-primary mb-1 mt-2">
                        Cài đặt thông tin dữ liệu nhà xe được phép chỉnh sửa khi sửa lệnh
                    </div>
                    <div
                        class="row mb-2"
                        v-for="(item, index) in DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh"
                        :key="index"
                    >
                        <DxCheckBox
                            v-model="item.select"
                            :text="item.TenThuocTinh"
                            class="mr-3"
                        />
                    </div>

                    <div
                        class="row mb-2"
                        v-if="
                            ChiTietCauHinhDoanhNghiepTheoMaCauHinh[
                                $t('MaCauHinhDoanhNghiep.SuDungQuyTrinhCanBoKiemTraXe')
                            ] == 'true'
                        "
                    >
                        <DxCheckBox
                            v-model="TuDongKyLenhKhiCanBoXacNhanKiemTraXe"
                            text="Tự động ký lệnh khi cán bộ kiểm tra xe xác nhận"
                        />
                    </div>

                    <DxValidationGroup ref="formValidation">
                        <div class="row mb-4 mt-4">
                            <div class="xs12">
                                <DxSelectBox
                                    label="Biển kiểm soát (*)"
                                    labelMode="floating"
                                    v-model="ThongTinKyLenh.ThongTinChung.XeThucHien"
                                    :dataSource="{
                                        store: ThongTinKyLenh.DanhSachXe,
                                        paginate: true,
                                    }"
                                    display-expr="BienKiemSoat"
                                    value-expr="IdDnvtXe"
                                    :search-enabled="true"
                                    :showClearButton="true"
                                    styling-mode="underlined"
                                    validationMessageMode="always"
                                    :onValueChanged="onValueChangedBienKiemSoat"
                                >
                                    <DxValidator>
                                        <DxRequiredRule
                                            message="Biển kiểm soát không được bỏ trống!"
                                        />
                                    </DxValidator>
                                </DxSelectBox>
                            </div>
                        </div>

                        <div class="row mb-4">
                            <div class="xs12">
                                <DxSelectBox
                                    label="Đội xe (*)"
                                    labelMode="floating"
                                    v-model="ThongTinKyLenh.ThongTinChung.DoiXe"
                                    :items="ThongTinKyLenh.DanhSachDoiXeDangKyTrenTuyen"
                                    display-expr="TenDoiXe"
                                    value-expr="IdDoiXe"
                                    :search-enabled="true"
                                    :showClearButton="true"
                                    styling-mode="underlined"
                                    validationMessageMode="always"
                                    :onValueChanged="onValueChangedDoiXe"
                                >
                                    <DxValidator>
                                        <DxRequiredRule
                                            message="Đội xe không được bỏ trống!"
                                        />
                                    </DxValidator>
                                </DxSelectBox>
                            </div>
                        </div>

                        <div class="row mb-4">
                            <div class="xs12">
                                <DxDateBox
                                    label="Hạn phù hiệu tuyến (*)"
                                    labelMode="floating"
                                    v-model:value="
                                        ThongTinKyLenh.ThongTinChung.HanPhuHieuTuyen
                                    "
                                    ref="KyDenNgay"
                                    displayFormat="dd/MM/yyyy"
                                    :use-mask-behavior="true"
                                    dropDownButtonTemplate="customIcon"
                                    validationMessageMode="always"
                                    styling-mode="underlined"
                                    autocomplete="off"
                                >
                                    <template #customIcon="{}">
                                        <DxButton icon="mdi mdi-calendar" />
                                    </template>
                                    <DxValidator>
                                        <DxRequiredRule
                                            message="Hạn phù hiệu không được bỏ trống!"
                                        />
                                        <DxRangeRule
                                            :max="MaxDate"
                                            :message="$i18n.t('DefaultString.MaxDate')"
                                        />
                                        <DxRangeRule
                                            :min="$Helper.Common.getEndDay(new Date())"
                                            message="Hạn phù hiệu phải lớn hơn ngày hiện tại!"
                                        />
                                    </DxValidator>
                                </DxDateBox>
                            </div>
                        </div>

                        <div class="row mb-4">
                            <div class="xs12">
                                <DxDateBox
                                    label="Hạn bảo hiểm xe (*)"
                                    labelMode="floating"
                                    v-model:value="
                                        ThongTinKyLenh.ThongTinChung.HanBaoHiemXe
                                    "
                                    ref="KyDenNgay"
                                    displayFormat="dd/MM/yyyy"
                                    :use-mask-behavior="true"
                                    dropDownButtonTemplate="customIcon"
                                    validationMessageMode="always"
                                    styling-mode="underlined"
                                    autocomplete="off"
                                >
                                    <template #customIcon="{}">
                                        <DxButton icon="mdi mdi-calendar" />
                                    </template>
                                    <DxValidator>
                                        <DxRequiredRule
                                            message="Hạn bảo hiểm xe không được bỏ trống!"
                                        />
                                        <DxRangeRule
                                            :max="MaxDate"
                                            :message="$i18n.t('DefaultString.MaxDate')"
                                        />
                                        <DxRangeRule
                                            :min="$Helper.Common.getEndDay(new Date())"
                                            message="Hạn bảo hiểm xe phải lớn hơn ngày hiện tại!"
                                        />
                                    </DxValidator>
                                </DxDateBox>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="xs12">
                                <DxDateBox
                                    label="Hạn đăng kiểm xe (*)"
                                    labelMode="floating"
                                    v-model:value="
                                        ThongTinKyLenh.ThongTinChung.HanDangKiemXe
                                    "
                                    ref="KyDenNgay"
                                    displayFormat="dd/MM/yyyy"
                                    :use-mask-behavior="true"
                                    dropDownButtonTemplate="customIcon"
                                    validationMessageMode="always"
                                    styling-mode="underlined"
                                    autocomplete="off"
                                >
                                    <template #customIcon="{}">
                                        <DxButton icon="mdi mdi-calendar" />
                                    </template>
                                    <DxValidator>
                                        <DxRequiredRule
                                            message="Hạn đăng kiểm xe không được bỏ trống!"
                                        />
                                        <DxRangeRule
                                            :max="MaxDate"
                                            :message="$i18n.t('DefaultString.MaxDate')"
                                        />
                                        <DxRangeRule
                                            :min="$Helper.Common.getEndDay(new Date())"
                                            message="Hạn đăng kiểm phải lớn hơn ngày hiện tại!"
                                        />
                                    </DxValidator>
                                </DxDateBox>
                            </div>
                        </div>

                        <div class="row mb-4">
                            <div class="xs12">
                                <DxSelectBox
                                    label="Nhà xe"
                                    labelMode="floating"
                                    v-model="ThongTinKyLenh.ThongTinChung.IdNhaXe"
                                    :dataSource="{
                                        store: ThongTinKyLenh.DanhSachNhaXe,
                                        paginate: true,
                                    }"
                                    value-expr="IdNhaXe"
                                    display-expr="TenNhaXe"
                                    :search-enabled="true"
                                    :showClearButton="true"
                                    styling-mode="underlined"
                                    validationMessageMode="always"
                                    :onItemClick="onItemClickNhaXe"
                                >
                                </DxSelectBox>
                            </div>
                        </div>

                        <div class="row mb-4">
                            <div class="xs12">
                                <DxSelectBox
                                    label="Giờ XB kế hoạch (*)"
                                    labelMode="floating"
                                    v-model="ThongTinKyLenh.ThongTinChung.KeHoach"
                                    :dataSource="{
                                        store: ThongTinKyLenh.DanhSachGioXBKeHoach,
                                        paginate: true,
                                    }"
                                    :display-expr="
                                        (x) => (x ? $Helper.formatTime(x.GioXuatBen) : ``)
                                    "
                                    :search-enabled="true"
                                    :showClearButton="true"
                                    styling-mode="underlined"
                                    validationMessageMode="always"
                                    :onValueChanged="onValueChangedGioXB"
                                >
                                    <DxValidator>
                                        <DxRequiredRule
                                            message="Giờ XB không được bỏ trống!"
                                        />
                                    </DxValidator>
                                </DxSelectBox>
                            </div>
                        </div>

                        <div class="row mb-4">
                            <div class="xs12">
                                <DxDateBox
                                    v-model="ThongTinKyLenh.ThongTinChung.GioXBKhac"
                                    label="Giờ XB khác"
                                    labelMode="floating"
                                    displayFormat="HH:mm"
                                    type="time"
                                    :use-mask-behavior="true"
                                    dropDownButtonTemplate="customIcon"
                                    validationMessageMode="always"
                                    styling-mode="underlined"
                                    :showClearButton="true"
                                    :onValueChanged="onValueChangedGioXBKhac"
                                >
                                    <template #customIcon="{}">
                                        <DxButton icon="mdi mdi-calendar" />
                                    </template>
                                </DxDateBox>
                            </div>
                        </div>

                        <div class="row mb-4">
                            <div class="xs12">
                                <!-- value-expr="IdDnvtLaiXe" -->
                                <DxTagBox
                                    label="Lái xe (*)"
                                    labelMode="floating"
                                    :data-source="ThongTinKyLenh.DanhSachTatCaLaiXe"
                                    v-model:value="
                                        ThongTinKyLenh.ThongTinChung.DanhSachLaiXeKyLenh
                                    "
                                    :display-expr="
                                        (x) => (x ? `${x.HoTen} (${x.SoGiayPhep})` : ``)
                                    "
                                    :show-selection-controls="true"
                                    :multiline="false"
                                    :show-multi-tag-only="true"
                                    :search-enabled="true"
                                    :showDropDownButton="true"
                                    styling-mode="underlined"
                                    validationMessageMode="always"
                                    :onValueChanged="onValueChangedLaiXe"
                                    :showClearButton="true"
                                    item-template="item"
                                    tag-template="tag"
                                >
                                    <template #item="{ data }">
                                        <div
                                            style="
                                                white-space: break-spaces;
                                                border-bottom: 1px solid #dadce0;
                                            "
                                            :class="
                                                CheckDuDieuKienLaiXe(data) == 'HetHan'
                                                    ? 'color-error'
                                                    : CheckDuDieuKienLaiXe(data) ==
                                                      'GanHetHan'
                                                    ? 'color-warning'
                                                    : ''
                                            "
                                        >
                                            {{
                                                data
                                                    ? `${data.HoTen} (${data.SoGiayPhep})`
                                                    : ""
                                            }}
                                        </div>
                                    </template>

                                    <template #tag="{ data }">
                                        <div class="dx-tag-content">
                                            <span
                                                :class="
                                                    CheckDuDieuKienLaiXe(data) == 'HetHan'
                                                        ? 'color-error'
                                                        : CheckDuDieuKienLaiXe(data) ==
                                                          'GanHetHan'
                                                        ? 'color-warning'
                                                        : ''
                                                "
                                            >
                                                {{
                                                    data
                                                        ? `${data.HoTen} (${data.SoGiayPhep})`
                                                        : ""
                                                }}
                                            </span>
                                            <div class="dx-tag-remove-button"></div>
                                        </div>
                                    </template>
                                    <DxValidator ref="validateLaiXeTiepNhanLenh">
                                        <DxRequiredRule
                                            message="Lái xe tiếp nhận lệnh không được bỏ trống!"
                                        />
                                    </DxValidator>
                                </DxTagBox>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="xs12">
                                <DxTextBox
                                    label="Phụ xe"
                                    labelMode="floating"
                                    v-model:value="ThongTinKyLenh.ThongTinChung.PhuXe"
                                    styling-mode="underlined"
                                >
                                    <DxValidator ref="">
                                        <DxStringLengthRule
                                            :max="48"
                                            message="Phụ xe không được lớn hơn 48 ký tự!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>

                        <div class="row mb-4">
                            <div class="xs12">
                                <DxDateBox
                                    label="Ký từ ngày (*)"
                                    labelMode="floating"
                                    v-model="ThongTinKyLenh.ThongTinChung.KyTuNgay"
                                    ref="KyTuNgay"
                                    displayFormat="dd/MM/yyyy"
                                    :use-mask-behavior="true"
                                    dropDownButtonTemplate="customIcon"
                                    validationMessageMode="always"
                                    styling-mode="underlined"
                                    :onValueChanged="onValueChangedLayNgay"
                                >
                                    <template #customIcon="{}">
                                        <DxButton icon="mdi mdi-calendar" />
                                    </template>

                                    <DxValidator>
                                        <DxRequiredRule
                                            message="Ký từ ngày không được bỏ trống!"
                                        />
                                        <DxRangeRule
                                            :max="
                                                ThongTinKyLenh.ThongTinChung.KyDenNgay ||
                                                new Date()
                                            "
                                            message="Ký từ ngày phải nhỏ hơn hoặc bằng Ký đến ngày"
                                        />
                                        <DxRangeRule
                                            :min="MinDate"
                                            :message="
                                                ChoPhepKyLenhNgayCu
                                                    ? `Ký từ ngày không được nhỏ hơn 7 ngày từ ngày hiện tại!`
                                                    : `Ký từ ngày không được nhỏ hơn ngày hiện tại!`
                                            "
                                        />
                                        <DxCustomRule
                                            :validationCallback="validationTuNgayDenNgay"
                                            :message="`Khoảng ngày chọn không được lớn hơn ${SoLuongNgayKyLenhToiDa}!`"
                                        />
                                    </DxValidator>
                                </DxDateBox>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="xs12">
                                <DxDateBox
                                    label="Ký đến ngày (*)"
                                    labelMode="floating"
                                    v-model="ThongTinKyLenh.ThongTinChung.KyDenNgay"
                                    ref="KyDenNgay"
                                    displayFormat="dd/MM/yyyy"
                                    :use-mask-behavior="true"
                                    dropDownButtonTemplate="customIcon"
                                    validationMessageMode="always"
                                    styling-mode="underlined"
                                    autocomplete="off"
                                    :onValueChanged="onValueChangedLayNgay"
                                >
                                    <template #customIcon="{}">
                                        <DxButton icon="mdi mdi-calendar" />
                                    </template>
                                    <DxValidator>
                                        <DxRequiredRule
                                            message="Ký đến ngày không được bỏ trống!"
                                        />
                                        <DxRangeRule
                                            :max="MaxDate"
                                            :message="$i18n.t('DefaultString.MaxDate')"
                                        />
                                        <DxRangeRule
                                            :min="
                                                ThongTinKyLenh.ThongTinChung.KyTuNgay ||
                                                new Date()
                                            "
                                            message="Ký đến ngày phải lớn hơn hoặc bằng Ký từ ngày"
                                        />
                                        <DxCustomRule
                                            :validationCallback="validationTuNgayDenNgay"
                                            :message="`Khoảng ngày chọn không được lớn hơn ${SoLuongNgayKyLenhToiDa}!`"
                                        />
                                    </DxValidator>
                                </DxDateBox>
                            </div>
                        </div>

                        <div class="row mb-4">
                            <div class="xs12">
                                <DxList
                                    :dataSource="ThongTinKyLenh.DanhSachNgayKeHoach"
                                    page-load-mode="nextButton"
                                    :hoverStateEnabled="false"
                                    :focusStateEnabled="false"
                                    :activeStateEnabled="false"
                                    :show-selection-controls="true"
                                    no-data-text="Không có dữ liệu!"
                                    class="list-lenh-style"
                                    selection-mode="all"
                                    select-all-mode="allPages"
                                    v-model:selected-item-keys="
                                        ThongTinKyLenh.DanhSachNgayDuocChonKyLenh
                                    "
                                >
                                    <template #item="{ data: item }">
                                        <div>
                                            <FrameVue :item="item" />
                                        </div>
                                    </template>
                                </DxList>
                            </div>
                        </div>
                    </DxValidationGroup>
                </div>
            </div>

            <div
                class="row"
                style="position: fixed; bottom: 0; left: 0; right: 0; background: #fff"
            >
                <div class="xs12">
                    <div
                        class="row justify-space-between"
                        style="
                            border-top: 1px solid #dadce0;
                            padding: 0 10px;
                            height: 50px;
                            align-items: center;
                        "
                    >
                        <div class="text-xs-center">
                            <DxButton
                                text="Hủy"
                                type="danger"
                                styling-mode="text"
                                width="100%"
                                class="color-danger"
                                @click="$router.go(-1)"
                            />
                        </div>
                        <div class="text-xs-center">
                            <DxButton
                                :text="
                                    'KÝ LỆNH (' +
                                    (ThongTinKyLenh.DanhSachNgayDuocChonKyLenh?.length ||
                                        0) +
                                    ')'
                                "
                                type="default"
                                styling-mode="contained"
                                class="color-primary"
                                @click="KyLenh()"
                                :disabled="
                                    (ThongTinKyLenh.DanhSachNgayDuocChonKyLenh?.length ||
                                        0) < 1
                                "
                            />
                        </div>
                    </div>
                </div>
            </div>

            <PopupVue
                height="auto"
                v-if="ifPopupMaBaoMat"
                v-model:dialog="PopupMaBaoMat"
                title="Nhập mã bảo mật"
                buttonTextRight="Xác nhận"
                buttonTextLeft="Hủy"
                @close="XacNhanMatMaKyLenh"
                classPopup="popup-padding-bottom-8 popup-padding-right-16 popup-padding-left-16"
                :key="`${componentKey}-3`"
            >
                <template #content>
                    <PopupMatMaKyLenhVue ref="popupMatMaLenh" />
                </template>
            </PopupVue>

            <PopupVue
                height="auto"
                v-if="ifPopupXacNhanNhanLenhSauKhiTaoLenh"
                v-model:dialog="PopupXacNhanNhanLenhSauKhiTaoLenh"
                title=""
                :closeOnOutsideClick="false"
                buttonTextLeft="Đóng"
                class="popup-padding-8"
                @close="XacNhanNhanLenhSauKhiTaoLenh"
                heightScrollView="auto"
            >
                <template #content>
                    <ThongBaoVue
                        :message="ParamThongBaoXacNhanNhanLenhSauKhiTaoLenh.message"
                        :state="ParamThongBaoXacNhanNhanLenhSauKhiTaoLenh.state"
                        :title="ParamThongBaoXacNhanNhanLenhSauKhiTaoLenh.title"
                    />
                </template>
            </PopupVue>

            <PopupVue
                height="auto"
                v-if="ifPopupXacNhanCapNhatThongTinLaiXeGoiY"
                v-model:dialog="PopupXacNhanCapNhatThongTinLaiXeGoiY"
                title=""
                :closeOnOutsideClick="false"
                buttonTextLeft="Đóng"
                class="popup-padding-8"
                @close="XacNhanCapNhatThongTinLaiXeGoiY"
                heightScrollView="auto"
            >
                <template #content>
                    <ThongBaoVue
                        :message="ParamThongBaoXacNhanCapNhatThongTinLaiXeGoiY.message"
                        :state="ParamThongBaoXacNhanCapNhatThongTinLaiXeGoiY.state"
                        :title="ParamThongBaoXacNhanCapNhatThongTinLaiXeGoiY.title"
                    />
                </template>
            </PopupVue>
        </ion-content>
    </ion-page>
</template>
<script>
import { DxTagBox } from "devextreme-vue/tag-box";
import { IonPage, IonHeader, IonContent, IonToolbar } from "@ionic/vue";
import { onIonViewWillEnter } from "@ionic/vue";
import {
    DxRequiredRule,
    DxStringLengthRule,
    DxRangeRule,
    DxCustomRule,
} from "devextreme-vue/data-grid";
import {
    DxButton,
    DxCheckBox,
    DxDateBox,
    DxSelectBox,
    DxTextBox,
    DxList,
    DxValidationGroup,
    DxValidator,
} from "devextreme-vue";
import FrameVue from "./components/Frame.vue";
import PopupVue from "../../../components/_Common/Popup.vue";
import PopupMatMaKyLenhVue from "./components/PopupMatMaKyLenh.vue";
import ThongBaoVue from "../../../components/_Common/ThongBao.vue";
import { mapGetters } from "vuex";
export default {
    components: {
        DxTextBox,
        DxList,
        DxValidationGroup,
        DxStringLengthRule,
        DxRangeRule,
        DxCustomRule,
        DxTagBox,
        IonPage,
        IonHeader,
        IonContent,
        IonToolbar,
        DxButton,
        DxCheckBox,
        DxDateBox,
        DxSelectBox,
        DxSelectBox,
        DxValidator,
        DxRequiredRule,
        FrameVue,
        PopupVue,
        PopupMatMaKyLenhVue,
        ThongBaoVue,
    },

    props: {},
    data() {
        return {
            SoLaiXeToiDa: 3,
            ifPopupMaBaoMat: false,
            PopupMaBaoMat: false,
            ifPopupXacNhanNhanLenhSauKhiTaoLenh: false,
            PopupXacNhanNhanLenhSauKhiTaoLenh: false,
            ParamThongBaoXacNhanNhanLenhSauKhiTaoLenh: {
                state: "Success",
                title: "Thông báo!",
                message: "Vui lòng kiểm tra lại",
            },
            ifPopupXacNhanCapNhatThongTinLaiXeGoiY: false,
            PopupXacNhanCapNhatThongTinLaiXeGoiY: false,
            ParamThongBaoXacNhanCapNhatThongTinLaiXeGoiY: {
                state: "Question",
                title: "Xác nhận cập nhật thông tin lái xe!",
                message:
                    "Tìm thấy thông tin lái xe gợi ý từ lần ký lệnh cuối cùng theo thông tin Tuyến vận chuyển, Đội xe, Nhà xe, Biển kiểm soát, Giờ xuất bến đã chọn. Bạn có muốn cập nhật thông tin đó vào thông tin lái xe hiện tại đang chọn không?",
            },
        };
    },
    computed: {
        ...mapGetters({
            SoLuongNgayKyLenhToiDa: "DVVTLenh/SoLuongNgayKyLenhToiDa",
            SoLuongLenhKyLenhToiDa: "DVVTLenh/SoLuongLenhKyLenhToiDa",
        }),
        DanhSachLenhCuaLaiXeSauKhiKyLenh: {
            get() {
                return this.$store.state.DVVTLenh.DanhSachLenhCuaLaiXeSauKhiKyLenh || [];
            },
            set(data) {
                this.$store.commit("DVVTLenh/Set", {
                    key: "DanhSachLenhCuaLaiXeSauKhiKyLenh",
                    data: data,
                });
            },
        },
        ThongTinKyLenh: {
            get() {
                return this.$store.state.DVVTLenh.ThongTinKyLenh || {};
            },
            set(data) {
                this.$store.commit("DVVTLenh/Set", {
                    key: "ThongTinKyLenh",
                    data: data,
                });
            },
        },
        DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh: {
            get() {
                return (
                    this.$store.state.DVVTLenh.DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh ||
                    []
                );
            },
            set(data) {
                this.$store.commit("DVVTLenh/Set", {
                    key: "DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh",
                    data: data,
                });
            },
        },
        ChiTietCauHinhDoanhNghiepTheoMaCauHinh() {
            return this.$store.state.DVVTLenh.ChiTietCauHinhDoanhNghiepTheoMaCauHinh;
        },
        ChoPhepKyLenhNgayCu() {
            let token = $Core.AuthApi.TokenParsed;
            if (
                token.hasOwnProperty("CauHinhLenh") &&
                token.CauHinhLenh.hasOwnProperty("DuocPhepCapLenhNhoNgayHienTai")
            ) {
                return token.CauHinhLenh.DuocPhepCapLenhNhoNgayHienTai;
            }
            return false;
        },
        MaxDate() {
            return this.$Helper.Common.getEndDay(this.$t("DefaultNumBer.MaxDate"));
        },
        MinDate() {
            if (this.ChoPhepKyLenhNgayCu) {
                return this.$Helper.getStartDay(
                    new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7, 0),
                );
            } else {
                return this.$Helper.getStartDay(new Date());
            }
        },
    },
    watch: {
        PopupXacNhanNhanLenhSauKhiTaoLenh() {
            if (!this.PopupXacNhanNhanLenhSauKhiTaoLenh) {
                this.NgatDOMPopup("ifPopupXacNhanNhanLenhSauKhiTaoLenh");
            }
        },
        PopupMaBaoMat() {
            if (!this.PopupMaBaoMat) {
                this.NgatDOMPopup("ifPopupMaBaoMat");
            }
        },
    },
    methods: {
        showPopup(name) {
            this["if" + name] = true;
            this[name] = true;
        },
        NgatDOMPopup(key) {
            setTimeout(() => {
                this[key] = false;
            }, 300);
        },
        InitData() {
            this.ThongTinKyLenh.ThongTinChung.TuDongKyLenhKhiCanBoXacNhanKiemTraXe = true;
            this.ThongTinKyLenh.ThongTinChung.KyTuNgay = this.$Helper.Common.getStartDay();
            this.ThongTinKyLenh.ThongTinChung.KyDenNgay = this.$Helper.Common.getEndDay();
        },
        CheckDuDieuKienLaiXe(data) {
            if (!data.NgayKetThucHopDong || !data.HanGiayPhep) {
                return "HetHan";
            }
            if (
                this.checkNgayKhongDuDieuKien({
                    date: data.NgayKetThucHopDong,
                    soNgay: 0,
                }) ||
                this.checkNgayKhongDuDieuKien({
                    date: data.HanGiayPhep,
                    soNgay: 0,
                })
            ) {
                return "HetHan";
            }
            if (
                this.checkNgayKhongDuDieuKien({
                    date: data.NgayKetThucHopDong,
                    soNgay: 15,
                }) ||
                this.checkNgayKhongDuDieuKien({
                    date: data.HanGiayPhep,
                    soNgay: 15,
                })
            ) {
                return "GanHetHan";
            }

            return "DuDieuKien";
        },
        checkNgayKhongDuDieuKien({ date, soNgay }) {
            let dateCheck = new Date();
            dateCheck.setDate(dateCheck.getDate() + soNgay);
            dateCheck.setHours(0);
            dateCheck.setMinutes(0);
            dateCheck.setSeconds(0);
            dateCheck.setMilliseconds(0);
            date = new Date(date);
            date.setHours(23);
            date.setMinutes(59);
            date.setSeconds(59);
            date.setMilliseconds(0);
            let soNgayTinhToan = (date - dateCheck) / 1000 / 24 / 60 / 60;
            return soNgayTinhToan < 0;
        },
        onValueChangedLaiXe(e) {
            if (e.value?.length > this.SoLaiXeToiDa) {
                const newValue = e.value.slice(0, this.SoLaiXeToiDa);
                e.component.option("value", newValue);
                this.$Helper.ThongBaoToast(
                    "warning",
                    `Chỉ được chọn tối đa ${this.SoLaiXeToiDa} lái xe!`,
                );
            }
        },
        async LayTatCaCauHinhChinhSuaKhiKyLenh() {
            let data = await this.LayCauHinhChinhSuaKhiKyLenh({});
            if (data.length > 0) {
                this.DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh = data.map((e) => {
                    e.select = true;
                    return e;
                });
            }
        },
        async LayCauHinhChinhSuaKhiKyLenh({ IdDnvtTuyen, IdDnvtXe, IdNhaXe }) {
            try {
                let rs = await this.$store.dispatch(
                    "DVVTLenh/LayCauHinhChinhSuaKhiKyLenh",
                    {
                        IdDnvtTuyen: IdDnvtTuyen,
                        IdDnvtXe: IdDnvtXe,
                        IdNhaXe: IdNhaXe,
                    },
                );

                if (!rs.status) {
                    this.$Helper.ThongBaoToast(
                        "error",
                        "Lấy cấu hình chỉnh sửa khi ký lệnh thất bại! " + rs.message,
                    );
                }
                return rs.data || [];
            } catch (error) {
                console.log(error);
            }
        },
        async LayDanhSachDoiXeDangKyTrenTuyen() {
            try {
                this.$startLoading;
                let rs = await this.$store.dispatch(
                    "DVVTLenh/LayDanhSachDoiXeDangKyTrenTuyen",
                    {
                        IdDnvtTuyen: this.ThongTinKyLenh.QueryRouter.IdDnvtTuyen,
                    },
                );
                if (rs.status) {
                    if (this.ThongTinKyLenh.QueryRouter.IdDoiXe) {
                        this.ThongTinKyLenh.ThongTinChung.DoiXe =
                            this.ThongTinKyLenh.QueryRouter.IdDoiXe + "";
                        this.ThongTinKyLenh.QueryRouter.IdDoiXe = null;
                    }
                }
            } catch (error) {
            } finally {
                this.$stopLoading;
            }
        },
        async LayDanhSachXeVaDoiXeDangKyHoatDongTrenTuyen() {
            try {
                this.$startLoading;
                let rs = await this.$store.dispatch(
                    "DVVTLenh/LayDanhSachXeVaDoiXeDangKyHoatDongTrenTuyen",
                    { IdDnvtTuyen: this.ThongTinKyLenh.QueryRouter.IdDnvtTuyen },
                );
                if (rs.status) {
                }
            } catch (error) {
            } finally {
                this.$stopLoading;
            }
        },
        async onValueChangedBienKiemSoat(e) {
            if (
                e.value &&
                !this.ThongTinKyLenh.LuongChonBienKiemSoatTruoc &&
                !this.ThongTinKyLenh.LuongChonDoiXeTruoc
            ) {
                this.ThongTinKyLenh.LuongChonBienKiemSoatTruoc = true;
            } else if (!e.value && !this.ThongTinKyLenh.ThongTinChung.DoiXe) {
                this.BoChonXeVaDoiXe();
            }

            // if (this.$refs.FrameVue) {
            //     this.$refs.FrameVue.getDataPost();
            // }
            // if (this.$refs.FrameChieuVeVue) {
            //     this.$refs.FrameChieuVeVue.getDataPost();
            // }

            if (this.ThongTinKyLenh.LuongChonBienKiemSoatTruoc) {
                this.ResetThongTinKyLenh();
                this.ThongTinKyLenh.DanhSachDoiXeDangKyTrenTuyen = [];
                if (e.value) {
                    let itemBienKiemSoat = this.ThongTinKyLenh.DanhSachXe.find(
                        (e) => e.IdDnvtXe == this.ThongTinKyLenh.ThongTinChung.XeThucHien,
                    );
                    if (itemBienKiemSoat) {
                        this.ThongTinKyLenh.DanhSachDoiXeDangKyTrenTuyen =
                            itemBienKiemSoat.DanhSachDangKyDoiXe;
                    }
                } else {
                    try {
                        this.$startLoading;
                        let rs = await this.$store.dispatch(
                            "DVVTLenh/LayDanhSachDoiXeDangKyTrenTuyen",
                            {
                                IdDnvtTuyen: this.ThongTinKyLenh.QueryRouter.IdDnvtTuyen,
                            },
                        );
                    } catch (error) {
                    } finally {
                        this.$stopLoading;
                    }
                }

                if (
                    this.ThongTinKyLenh.DanhSachDoiXeDangKyTrenTuyen.length > 0 &&
                    e.value
                ) {
                    if (!this.ThongTinKyLenh.ThongTinChung.DoiXe) {
                        this.ThongTinKyLenh.ThongTinChung.DoiXe =
                            this.ThongTinKyLenh.DanhSachDoiXeDangKyTrenTuyen[0].IdDoiXe ||
                            null;
                    } else {
                        let itemDoiXe = this.ThongTinKyLenh.DanhSachDoiXeDangKyTrenTuyen.find(
                            (e) => e.IdDoiXe == this.ThongTinKyLenh.ThongTinChung.DoiXe,
                        );
                        if (!itemDoiXe) {
                            this.ThongTinKyLenh.ThongTinChung.DoiXe =
                                this.ThongTinKyLenh.DanhSachDoiXeDangKyTrenTuyen[0]
                                    .IdDoiXe || null;
                        } else {
                            this.GetChiTietKeHoach({
                                IdDoiXe: this.ThongTinKyLenh.ThongTinChung.DoiXe,
                                IdDnvtTuyen: this.ThongTinKyLenh.QueryRouter.IdDnvtTuyen,
                                IdBenXuatPhat: this.ThongTinKyLenh.QueryRouter.IdBenDi,
                            });
                        }
                    }
                } else {
                    this.ThongTinKyLenh.ThongTinChung.DoiXe = null;
                }
            } else {
                this.LayGoiYThongTinKyLenh();
            }
            if (e.value) {
                let item = this.ThongTinKyLenh.DanhSachXe.find(
                    (xe) => xe.IdDnvtXe == e.value,
                );
                this.ThongTinKyLenh.ThongTinChung.HanPhuHieuTuyen = item.HanPhuHieu
                    ? this.$Helper.Common.getEndDay(item.HanPhuHieu)
                    : null;
                this.ThongTinKyLenh.ThongTinChung.HanBaoHiemXe = item.HanBaoHiem
                    ? this.$Helper.Common.getEndDay(item.HanBaoHiem)
                    : null;
                this.ThongTinKyLenh.ThongTinChung.HanDangKiemXe = item.HanDangKiem
                    ? this.$Helper.Common.getEndDay(item.HanDangKiem)
                    : null;
                this.ThongTinKyLenh.ThongTinChung.HanBienNhanTheChap = item.HanBienNhanTheChap
                    ? this.$Helper.Common.getEndDay(item.HanBienNhanTheChap)
                    : null;
            }
            let data = await this.LayCauHinhChinhSuaKhiKyLenh({
                IdDnvtTuyen: this.ThongTinKyLenh.ThongTinChung.IdDnvtTuyen,
                IdDnvtXe: this.ThongTinKyLenh.ThongTinChung.XeThucHien,
                IdNhaXe: this.ThongTinKyLenh.ThongTinChung.IdNhaXe,
            });
            if (data.length > 0) {
                this.DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh = this.DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh.map(
                    (e) => {
                        let check = data.some((dt) => dt.MaThuocTinh == e.MaThuocTinh);
                        e.select = check;
                        return e;
                    },
                );
            }
        },
        onValueChangedDoiXe(e) {
            if (
                e.value &&
                !this.ThongTinKyLenh.LuongChonBienKiemSoatTruoc &&
                !this.ThongTinKyLenh.LuongChonDoiXeTruoc
            ) {
                this.ThongTinKyLenh.LuongChonDoiXeTruoc = true;
            } else if (!e.value && !this.ThongTinKyLenh.ThongTinChung.XeThucHien) {
                this.BoChonXeVaDoiXe();
            }
            this.ResetThongTinKyLenh();

            if (this.ThongTinKyLenh.LuongChonDoiXeTruoc) {
                this.ThongTinKyLenh.DanhSachXe = [];
            }
            if (e.value) {
                this.GetChiTietKeHoach({
                    IdDoiXe: this.ThongTinKyLenh.ThongTinChung.DoiXe,
                    IdDnvtTuyen: this.ThongTinKyLenh.QueryRouter.IdDnvtTuyen,
                    IdBenXuatPhat: this.ThongTinKyLenh.QueryRouter.IdBenDi,
                });
            }
        },
        onItemClickNhaXe(e) {
            if (e?.itemData?.IdNhaXe) {
                this.LayGoiYThongTinKyLenh();
            }
        },
        async onValueChangedGioXB(e) {
            this.LayNgay();
            if (
                this.ThongTinKyLenh.ThongTinChung.KeHoach &&
                !this.ThongTinKyLenh.ThongTinChung.GioXBKhac &&
                e.previousValue?.IdKeHoach != e.value?.IdKeHoach
            ) {
                this.LayGoiYThongTinKyLenh();
            }
        },
        onValueChangedLayNgay() {
            if (!this.validationTuNgayDenNgay()) {
                return;
            }
            this.LayNgay();
        },
        validationTuNgayDenNgay() {
            let TuNgay = new Date(
                this.$Helper.Common.getStartDay(
                    this.ThongTinKyLenh.ThongTinChung.KyTuNgay,
                ),
            ).getTime();
            let DenNgay = new Date(
                this.$Helper.Common.getEndDay(
                    this.ThongTinKyLenh.ThongTinChung.KyDenNgay,
                ),
            ).getTime();

            let minusResult = (DenNgay - TuNgay) / (1000 * 60 * 60 * 24);
            if (minusResult > this.SoLuongNgayKyLenhToiDa) return false;
            return true;
        },
        async GetChiTietKeHoach({ IdDoiXe, IdDnvtTuyen, IdBenXuatPhat }) {
            this.$startLoading;
            let DsNhaXe = [];
            let DsBienSoXe = [];
            let DanhSachTatCaLaiXe = [];
            let DanhSachGioXuatBen = [];
            try {
                let rs = await this.$Events.RequestGet({
                    ServicesBE: "QuanLyLenh",
                    url: this.$t("urlDNQuanLyLenh.ChiTietKeHoachTuyenVersion2"),
                    dataGet: {
                        IdDoiXe: IdDoiXe,
                        IdDnvtTuyen: IdDnvtTuyen,
                        IdBenXuatPhat: IdBenXuatPhat,
                    },
                    msgSuccess: "",
                    msgError: "Lấy thông tin chi tiết kế hoạch thất bại!",
                    msgCatch: "Lấy thông tin chi tiết kế hoạch thất bại!",
                });
                if (rs.status) {
                    DsNhaXe = rs.data.DanhSachNhaXe;
                    DsBienSoXe = rs.data.DanhSachXe;
                    DanhSachTatCaLaiXe = rs.data.DanhSachLaiXe;
                    DanhSachGioXuatBen = rs.data.DanhSachGioXuatBen;
                }
            } catch (error) {
                this.$Helper.ThongBaoToast(
                    "error",
                    "Lấy danh sách nhà xe, phương tiện và lái xe dự kiến thất bại!",
                );
                DsNhaXe = [];
                DsBienSoXe = [];
                DanhSachTatCaLaiXe = [];
                DanhSachGioXuatBen = [];
            } finally {
                this.$stopLoading;
                if (this.ThongTinKyLenh.LuongChonDoiXeTruoc) {
                    this.ThongTinKyLenh.DanhSachXe = this.$Helper.CloneData(DsBienSoXe);
                    let itemXe = this.ThongTinKyLenh.DanhSachXe.find(
                        (e) => e.IdDnvtXe == this.ThongTinKyLenh.ThongTinChung.XeThucHien,
                    );
                    if (!itemXe) {
                        this.ThongTinKyLenh.ThongTinChung.XeThucHien = null;
                        this.ThongTinKyLenh.ThongTinChung.HanPhuHieuTuyen = null;
                        this.ThongTinKyLenh.ThongTinChung.HanBaoHiemXe = null;
                        this.ThongTinKyLenh.ThongTinChung.HanDangKiemXe = null;
                        this.ThongTinKyLenh.ThongTinChung.HanBienNhanTheChap = null;
                    }
                }
                this.ThongTinKyLenh.DanhSachNhaXe = this.$Helper.CloneData(DsNhaXe);
                if (this.ThongTinKyLenh.DanhSachNhaXe.length == 1) {
                    this.ThongTinKyLenh.ThongTinChung.IdNhaXe = this.ThongTinKyLenh.DanhSachNhaXe[0].IdNhaXe;
                }
                this.ThongTinKyLenh.DanhSachTatCaLaiXe = this.$Helper.CloneData(
                    DanhSachTatCaLaiXe,
                );
                this.ThongTinKyLenh.DanhSachGioXBKeHoach = this.$Helper.CloneData(
                    this.$Helper.SortWithKey(DanhSachGioXuatBen, "GioXuatBen"),
                );
                if (this.ThongTinKyLenh.QueryRouter.ID_KeHoach) {
                    if (IdBenXuatPhat == this.ThongTinKyLenh.QueryRouter.IdBenDi) {
                        let itemGioXB = this.ThongTinKyLenh.DanhSachGioXBKeHoach.find(
                            (e) =>
                                e.IdKeHoach == this.ThongTinKyLenh.QueryRouter.ID_KeHoach,
                        );
                        this.ThongTinKyLenh.ThongTinChung.KeHoach = itemGioXB;
                        this.ThongTinKyLenh.QueryRouter.ID_KeHoach = null;
                    }
                }
                this.LayGoiYThongTinKyLenh();
            }
        },
        LayGoiYThongTinKyLenh() {
            try {
                this.$store.dispatch("DVVTLenh/LayGoiYThongTinKyLenh", {
                    IdDoiXe: this.ThongTinKyLenh.ThongTinChung.DoiXe,
                    IdNhaXe: this.ThongTinKyLenh.ThongTinChung.IdNhaXe || null,
                    IdDnvtTuyen: this.ThongTinKyLenh.QueryRouter.IdDnvtTuyen,
                    IdBenXuatPhat: this.ThongTinKyLenh.QueryRouter.IdBenDi,
                    IdDnvtXe: this.ThongTinKyLenh.ThongTinChung.XeThucHien,
                    GioXuatBen: this.ThongTinKyLenh.ThongTinChung.GioXBKhac
                        ? this.$Helper.convertDatetimeToCurrentDate(
                              this.ThongTinKyLenh.ThongTinChung.GioXBKhac,
                          )
                        : this.ThongTinKyLenh.ThongTinChung.KeHoach
                        ? this.ThongTinKyLenh.ThongTinChung.KeHoach?.GioXuatBen
                        : null,
                });
            } catch (error) {}
        },
        async LayNgay() {
            try {
                this.ThongTinKyLenh.DanhSachNgayKeHoach = [];
                this.ThongTinKyLenh.DanhSachNgayDuocChonKyLenh = [];
                if (
                    this.ThongTinKyLenh.ThongTinChung.KeHoach &&
                    this.ThongTinKyLenh.ThongTinChung.KyTuNgay &&
                    this.ThongTinKyLenh.ThongTinChung.KyDenNgay &&
                    this.ThongTinKyLenh.ThongTinChung.KyTuNgay <=
                        this.ThongTinKyLenh.ThongTinChung.KyDenNgay &&
                    this.ThongTinKyLenh.ThongTinChung.KyDenNgay < this.MaxDate &&
                    this.ThongTinKyLenh.ThongTinChung.KyTuNgay >= this.MinDate
                ) {
                    try {
                        this.$startLoading;
                        let DanhSachNgayKeHoach = [];
                        let STT = 0;
                        let dayOfTime = await this.$store.dispatch(
                            "TinhToanNgayChay/LaySoNgayTrongKhoangThoiGian",
                            {
                                TuNgay: this.ThongTinKyLenh.ThongTinChung.KyTuNgay,
                                DenNgay: this.ThongTinKyLenh.ThongTinChung.KyDenNgay,
                            },
                        );

                        for (let i = 0; i < dayOfTime; i++) {
                            let day = new Date(
                                this.ThongTinKyLenh.ThongTinChung.KyTuNgay,
                            );
                            let past = new Date(day);
                            past.setDate(day.getDate() - 1);
                            let nextDay = new Date(past);
                            nextDay.setDate(past.getDate() + i);
                            let arrTemp = this.$Helper.Common.formatDate(
                                nextDay.setDate(nextDay.getDate() + 1),
                            ).split("/");

                            let checkTonTaiNgayKeHoach = await this.$store.dispatch(
                                "TinhToanNgayChay/KiemTraTonTaiNgayKeHoach",
                                {
                                    NgayKiemTra: nextDay,
                                    KeHoach: this.ThongTinKyLenh.ThongTinChung.KeHoach,
                                },
                            );
                            if (checkTonTaiNgayKeHoach) {
                                STT++;
                                DanhSachNgayKeHoach.push({
                                    STT: STT,
                                    NgayTrongTuan: nextDay.getDay(),
                                    NgayDuong: nextDay,
                                    NgayAm: this.$Helper.getLunarDateText(
                                        parseInt(arrTemp[0]),
                                        parseInt(arrTemp[1]),
                                        parseInt(arrTemp[2]),
                                    ),
                                    Ngay: nextDay,
                                });
                            }
                        }

                        this.ThongTinKyLenh.DanhSachNgayKeHoach = [
                            ...DanhSachNgayKeHoach,
                        ];
                    } catch (ex) {
                    } finally {
                        this.$stopLoading;
                    }

                    if (this.$refs.ThongTinKyLenh.DanhSachNgayKeHoach) {
                        this.$refs.ThongTinKyLenh.DanhSachNgayKeHoach.instance.refresh();
                    }
                }
            } catch (error) {}
        },
        async BoChonXeVaDoiXe() {
            this.ResetThongTinKyLenh();
            this.ThongTinKyLenh.LuongChonBienKiemSoatTruoc = false;
            this.ThongTinKyLenh.LuongChonDoiXeTruoc = false;
            this.LayDanhSachDoiXeDangKyTrenTuyen();
            this.LayDanhSachXeVaDoiXeDangKyHoatDongTrenTuyen();
        },
        ResetThongTinKyLenh() {
            this.ThongTinKyLenh.DanhSachNhaXe = [];
            // this.$store.commit("DVVTLenh/Reset_ThongTinKyLenh.DanhSachLaiXe");
            // this.$store.commit("DVVTLenh/Reset_ThongTinKyLenh.DanhSachLaiXeChieuVe");
            this.ThongTinKyLenh.DanhSachGioXBKeHoach = [];
            this.ThongTinKyLenh.DanhSachNgayKeHoach = [];
            this.ThongTinKyLenh.ThongTinChung.IdNhaXe = null;
        },
        async KyLenh() {
            let valid = this.$refs.formValidation.instance.validate();
            if (!valid.isValid) {
                this.$Helper.Common.autoFocusError(valid);
                return;
            } else {
                if (
                    this.ThongTinKyLenh.DanhSachNgayDuocChonKyLenh.length >
                    this.SoLuongLenhKyLenhToiDa
                ) {
                    return this.$Helper.ThongBaoPopup({
                        type: "Error",
                        message_title: "Thông tin ký lệnh không hợp lệ!",
                        message: `Số lệnh được phép ký trong 1 phiên không được vượt quá ${this.SoLuongLenhKyLenhToiDa}!`,
                    });
                }

                let rsCapNhatThongTinGiayToXe = await this.CapNhatThongTinGiayToXe();
                if (!rsCapNhatThongTinGiayToXe) {
                    return;
                }
                this.showPopup("PopupMaBaoMat");
            }
        },
        async CapNhatThongTinGiayToXe() {
            try {
                this.$startLoading;
                const thongTinGiayToXe = {
                    iD_DnvtTuyen: this.ThongTinKyLenh.QueryRouter.IdDnvtTuyen,
                    iD_DnvtXe: this.ThongTinKyLenh.ThongTinChung.XeThucHien,
                    hanBaoHiem: this.ThongTinKyLenh.ThongTinChung.HanBaoHiemXe
                        ? this.$Helper.Common.getEndDay(
                              this.ThongTinKyLenh.ThongTinChung.HanBaoHiemXe,
                          )
                        : null,
                    hanDangKiem: this.ThongTinKyLenh.ThongTinChung.HanDangKiemXe
                        ? this.$Helper.Common.getEndDay(
                              this.ThongTinKyLenh.ThongTinChung.HanDangKiemXe,
                          )
                        : null,
                    hanBienNhanTheChap: this.ThongTinKyLenh.ThongTinChung
                        .HanBienNhanTheChap
                        ? this.$Helper.Common.getEndDay(
                              this.ThongTinKyLenh.ThongTinChung.HanBienNhanTheChap,
                          )
                        : null,
                    hanPhuHieu: this.ThongTinKyLenh.ThongTinChung.HanPhuHieuTuyen
                        ? this.$Helper.Common.getEndDay(
                              this.ThongTinKyLenh.ThongTinChung.HanPhuHieuTuyen,
                          )
                        : null,
                };
                let result = await this.$store.dispatch(
                    "ThongTin/CapNhatThongTinGiayToXe",
                    thongTinGiayToXe,
                );
                if (result.status == true) {
                    return true;
                } else {
                    this.$Helper.ThongBaoPopup({
                        message_title: "Cập nhật thông tin giấy tờ xe thất bại!",
                        message: `${result.message}`,
                    });
                    return false;
                }
            } catch (error) {
                console.log("🚀 ~ error", error);
                this.$Helper.ThongBaoPopup({
                    message_title: "Cập nhật thông tin giấy tờ xe thất bại!",
                    message: "Lỗi hệ thống",
                });
                return false;
            } finally {
                this.$stopLoading;
            }
        },
        async XacNhanMatMaKyLenh(param) {
            if (!param) {
                this.PopupMaBaoMat = false;
                return;
            }

            let validMK = this.$refs.popupMatMaLenh.$refs.validateMaBaoMat.instance.validate();
            if (!validMK.isValid) {
                return this.$Helper.Common.autoFocusError(validMK);
            }

            if (!this.isKhoaLuong) {
                this.isKhoaLuong = true;
                try {
                    this.$startLoading;
                    let MaBaoMat = this.$refs.popupMatMaLenh.MaBaoMat;
                    let DataPost = this.GetDataPost(MaBaoMat);
                    let rsKyLenh = {
                        Data: {
                            status: true,
                            data: [],
                        },
                    };
                    rsKyLenh = await this.$store.dispatch(
                        "DVVTLenh/TaoVaKyLenh",
                        DataPost,
                    );
                    if (!rsKyLenh.Data.status) {
                        this.$Helper.ThongBaoPopup({
                            message_title: "Tạo lệnh thất bại!",
                            message: `- Thông tin lỗi: ${rsKyLenh.Data.message}\n`,
                        });
                    } else if (rsKyLenh.Data.status) {
                        let thongBaoTonTaiLenh = ``;
                        if (rsKyLenh.Data.data && rsKyLenh.Data.data.length > 0) {
                            rsKyLenh.Data.data.forEach((e, i) => {
                                if (i == 0) {
                                    thongBaoTonTaiLenh += `- Thông tin lỗi: \n`;
                                }
                                thongBaoTonTaiLenh += `+ ${e.ThongTinLoi}\n`;
                            });
                            this.$Helper.ThongBaoPopup({
                                type: "Warning",
                                message_title: "Thông tin kết quả tạo lệnh!",
                                message: `${thongBaoTonTaiLenh}`,
                            });
                        } else {
                            this.PopupMaBaoMat = false;
                            this.ParamThongBaoXacNhanNhanLenhSauKhiTaoLenh.title =
                                "Tạo lệnh thành công!";
                            this.ParamThongBaoXacNhanNhanLenhSauKhiTaoLenh.message =
                                "Bạn có muốn tiếp tục nhận lệnh không?";
                            this.ParamThongBaoXacNhanNhanLenhSauKhiTaoLenh.state =
                                "Success";
                            this.showPopup("PopupXacNhanNhanLenhSauKhiTaoLenh");
                        }
                    }
                    return;
                } catch (error) {
                    // // console.log("🚀 ~ error", error);
                    this.$Helper.ThongBaoPopup({
                        message_title: "Tạo lệnh thất bại!",
                        message: "Lỗi hệ thống",
                    });
                } finally {
                    this.PopupMaBaoMat = false;
                    this.isKhoaLuong = false;
                    this.$stopLoading;
                }
            }
        },
        GetDataPost(MaBaoMat) {
            let arrPost = [];
            let Not = null;
            if (this.ThongTinKyLenh.ThongTinChung.GioXBKhac) {
                Not = this.$Helper.convertDatetimeToCurrentDate(
                    this.ThongTinKyLenh.ThongTinChung.GioXBKhac,
                );
            }
            arrPost = this.ThongTinKyLenh.DanhSachNgayDuocChonKyLenh.map((e) => ({
                MaBaoMat: MaBaoMat,
                IdKeHoach: this.ThongTinKyLenh.ThongTinChung.KeHoach?.IdKeHoach,
                IdDnvtTuyen: this.ThongTinKyLenh.QueryRouter.IdDnvtTuyen,
                IdBenXeXuatPhat: this.ThongTinKyLenh.QueryRouter.IdBenDi,
                IdDoiXe: this.ThongTinKyLenh.ThongTinChung.DoiXe,
                Not: Not ? Not : this.ThongTinKyLenh.ThongTinChung.KeHoach?.GioXuatBen,
                NgayXuatBen: this.$Helper.getStartDay(e.NgayDuong),
                IdDnvtXe: this.ThongTinKyLenh.ThongTinChung.XeThucHien,
                LaiXeChinh_IdDnvtLaixe:
                    this.ThongTinKyLenh.ThongTinChung.DanhSachLaiXeKyLenh[0]
                        ?.IdDnvtLaiXe || null,
                LaiXeDiCung_IdDnvtLaixes:
                    this.ThongTinKyLenh.ThongTinChung.DanhSachLaiXeKyLenh.length > 1
                        ? this.ThongTinKyLenh.ThongTinChung.DanhSachLaiXeKyLenh.filter(
                              (item, index) => index !== 0 && item.IdDnvtLaiXe,
                          ).map((item) => item.IdDnvtLaiXe)
                        : [],
                PhuXes: this.ThongTinKyLenh.ThongTinChung.PhuXe
                    ? [this.ThongTinKyLenh.ThongTinChung.PhuXe]
                    : [],
                IdNhaXe: this.ThongTinKyLenh.ThongTinChung.IdNhaXe,
                KyLenhKhiCanBoXacNhanKiemTraXe:
                    this.ChiTietCauHinhDoanhNghiepTheoMaCauHinh[
                        this.$t("MaCauHinhDoanhNghiep.SuDungQuyTrinhCanBoKiemTraXe")
                    ] == "true"
                        ? this.ThongTinKyLenh.ThongTinChung
                              .TuDongKyLenhKhiCanBoXacNhanKiemTraXe
                        : false,
                PhanQuyenDuLieuChinhSua: this.DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh.filter(
                    (e) => e.select,
                ).map((e) => e.MaThuocTinh),
            }));
            return arrPost;
        },
        XacNhanNhanLenhSauKhiTaoLenh(bool) {
            if (bool) {
                this.PopupXacNhanNhanLenhSauKhiTaoLenh = false;
                this.LayThongTinLenhSauKhiTaoLenh();
            } else {
                this.PopupXacNhanNhanLenhSauKhiTaoLenh = false;
                this.$router.go(-1);
            }
        },
        XacNhanCapNhatThongTinLaiXeGoiY(bool) {
            if (bool) {
                this.PopupXacNhanCapNhatThongTinLaiXeGoiY = false;
                this.ThongTinKyLenh.ThongTinChung.DanhSachLaiXeKyLenh = [];
                this.ThongTinKyLenh.DanhSachIdLaiXeGoiY.forEach((e) => {
                    let selectData = JSON.parse(
                        JSON.stringify(
                            this.ThongTinKyLenh.DanhSachTatCaLaiXe.find(
                                (x) => x.IdDnvtLaiXe == e,
                            ),
                        ),
                    );
                    this.ThongTinKyLenh.ThongTinChung.DanhSachLaiXeKyLenh.push(
                        selectData,
                    );
                });
            } else {
                this.PopupXacNhanCapNhatThongTinLaiXeGoiY = false;
            }
        },
        async LayThongTinLenhSauKhiTaoLenh() {
            try {
                this.DanhSachLenhCuaLaiXeSauKhiKyLenh = [];
                let DanhSachNgayKyLenh = this.ThongTinKyLenh.DanhSachNgayDuocChonKyLenh.map(
                    (e) => e.NgayDuong,
                );
                DanhSachNgayKyLenh.sort((a, b) => a - b);
                for (let index = 0; index < DanhSachNgayKyLenh.length; index++) {
                    if (this.DanhSachLenhCuaLaiXeSauKhiKyLenh.length > 5) {
                        break;
                    }
                    let item = DanhSachNgayKyLenh[index];
                    let tmpDanhSachLenhLaiXe = await this.LayLenhCuaLaiXeSauKhiKyLenh(
                        item,
                    );
                    this.DanhSachLenhCuaLaiXeSauKhiKyLenh.push(...tmpDanhSachLenhLaiXe);
                }
                this.$router.go(-1);
                setTimeout(() => {
                    this.$router.push("/Nhan-Lenh-Sau-Khi-Tao-Lenh");
                }, 100);
            } catch (error) {
                // // console.log("🚀 ~ error:", error);
            }
        },
        async LayLenhCuaLaiXeSauKhiKyLenh(NgayXuatBen) {
            try {
                let query = {
                    timKiem: null,
                    idLuongTuyen: null,
                    ngayXuatBenKeHoach: this.$Helper.Common.getEndDay(NgayXuatBen),
                    danhSachGioXuatBen: [],
                };
                let rs = await this.$store.dispatch(
                    "DVVTLenh/LayLenhCuaLaiXeSauKhiKyLenh",
                    {
                        query,
                    },
                );
                return rs;
            } catch (error) {
                // // console.log(
                // // "🚀 ~ file: index.vue:789 ~ LayLenhCuaLaiXeSauKhiKyLenh ~ error:",
                // // error,
                // // );
            }
        },
    },
    created() {
        onIonViewWillEnter(() => {
            this.ThongTinKyLenh.QueryRouter = {};
            let query = this.$route.query;
            this.ThongTinKyLenh.QueryRouter = { ...query };
            if (
                !this.ThongTinKyLenh.QueryRouter.TenTuyen ||
                !this.ThongTinKyLenh.QueryRouter.TenBenDi ||
                !this.ThongTinKyLenh.QueryRouter.TenBenDen ||
                !this.ThongTinKyLenh.QueryRouter.IdDnvtTuyen ||
                !this.ThongTinKyLenh.QueryRouter.IdBenDi ||
                !this.ThongTinKyLenh.QueryRouter.IdBenDen
            ) {
                return this.$Helper.ThongBaoPopup({
                    message_title: "Lấy thông tin ký lệnh thất bại!",
                    message: `Dữ liệu không hợp lệ`,
                });
            }
            this.InitData();
            this.LayTatCaCauHinhChinhSuaKhiKyLenh();
            this.LayDanhSachDoiXeDangKyTrenTuyen();
            this.LayDanhSachXeVaDoiXeDangKyHoatDongTrenTuyen();
            // this.LayChiTietCauHinhDoanhNghiepTheoMaCauHinh();

            let self = this;
            this.emitter.on("CapNhatThongTinLaiXeGoiY", () => {
                self.showPopup("PopupXacNhanCapNhatThongTinLaiXeGoiY");
            });
        });
    },
    mounted() {},
};
</script>
<style scoped></style>
<style lang="scss" scoped></style>
